@import "../mixins/media";
@import "../mixins/clearfix";

/*
カテゴリ（アイテム3列）

トップページで使用されているカテゴリのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.7.1
*/

.ec-categoryRole {
	padding: 40px 0;
	color: black;
	background: #f8f8f8;

	@include media_desktop {
		padding: 60px 0;
	}

	& &__list {
		display: flex;
		flex-wrap: wrap;

		@include media_desktop {
			flex-wrap: nowrap;
		}
	}
	& &__listItem {
		margin-bottom: 10px;
		margin: 0 0 10px 2.75%;
		width: 31.5%;
		height: auto;

		&:nth-child(3n + 1) {
			margin-left: 0%;
		}

		@include media_desktop {
			width: calc(100% / 3);
			margin: 0 0 20px 0;

			&:not(:last-of-type) {
				margin-right: 20px;
			}
		}
	}
}
