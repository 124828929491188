@import "../mixins/media";
@import "../mixins/variables";
/*
見出し

ページ内で見出しとして機能する要素のスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.1
*/

/*
見出し

商品紹介等で利用される、一般的な見出しのスタイルです。

ex [商品詳細ページ　商品見出し部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-headingTitle マトリョーシカ

Styleguide 1.1.1
*/
.ec-headingTitle {
	margin: 0 0 8px;
	font-size: 32px;
	font-weight: normal;
	color: #525263;
}

/*
ページヘッダ

各種ページで用いられるページヘッダのデザインです。

ex [利用規約ページ　ページヘッダ部](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-pageHeader
  h1 利用規約

Styleguide 1.1.2
*/
.ec-pageHeader h1 {
	margin: 0 0 8px;
	border-bottom: 1px dotted #ccc;
	border-top: 1px solid #ccc;
	padding: 8px 0 12px;
	font-size: 16px;
	font-weight: bold;
	@include media_desktop {
		border-top: none;
		border-bottom: 1px solid #ccc;
		margin: 10px 0 48px;
		padding: 8px;
		font-size: 32px;
		font-weight: bold;
	}
}

/*
サブ見出し

利用規約など、文字主体のページで用いられるサブ見出しです。

ex [利用規約ページ サブ見出し部分](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-heading 第1条 (会員)

Styleguide 1.1.3
*/

.ec-heading {
	margin: 24px 0;
}

/*
サブ見出し(太字)

文字主体のページで用いられるサブ見出しの太字のスタイルです。

ex [プライバシーポリシー サブ見出し部分](http://demo3.ec-cube.net/help/privacy)

Markup:
.ec-heading-bold 個人情報の定義

Styleguide 1.1.4
*/

.ec-heading-bold {
	margin: 16px 0;
	font-size: 16px;
	font-weight: bold;
	@include media_desktop {
		font-size: 18px;
	}
}

/*
背景付き見出し

マイページ注文履歴等で用いられる背景付きの見出しです。

ex [ご注文履歴詳細　背景付き見出し部分](http://demo3.ec-cube.net/mypage/history/1063)

Markup:
.ec-rectHeading
  h2 配送情報
.ec-rectHeading
  h2 お支払について

Styleguide 1.1.5
*/
.ec-rectHeading {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		background: $clrGray;
		padding: 8px 12px;
		font-size: 20px;
		font-weight: bold;
	}
}

/*
メッセージ見出し

ユーザが行った操作に対する、完了報告やエラー表示のページで使用される見出しのスタイルです。

ex [注文完了 ログイン後、カートに商品を入れ注文完了まで行う](http://demo3.ec-cube.net/shopping/)

Markup:
.ec-reportHeading
  h2 ご注文ありがとうございました

Styleguide 1.1.6
*/
.ec-reportHeading {
	width: 100%;
	margin: 20px 0 30px;
	padding: 0;
	text-align: center;
	font-size: 24px;
	font-weight: bold;
	@include media_desktop {
		border-top: 0;
		font-size: 32px;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		font-weight: bold;
		font-size: 24px;
		@include media_desktop {
			font-size: 32px;
		}
	}
}
