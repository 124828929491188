// MediaQuery
$tablet: 480px;
$desktop: 768px;
$desktop2: 992px;
$desktop3: 1200px;

$font-size: 16px;

$clrRed: #de5d50;
$clrRich: #9a947e;
$clrGray: #f3f3f3;
$clrRightGray: #b8bec4;
$clrExtraGray: #636378;
$clrDarkGray: #525263;
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
