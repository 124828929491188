@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/clearfix";
@import "../mixins/animation";

/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [トップページ　ヘッダー](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.2.header.pug
include /assets/tmpl/elements/11.3.headerNavi.pug
include /assets/tmpl/elements/11.4.categoryNavi.pug
+b.ec-layoutRole
  +e.header
    +ec-headerRole
    +ec-headerNaviRole
    +ec-categoryNaviRole

Styleguide 11.2
*/
.ec-layoutRole {
	width: 100%;
	transition: transform 0.3s;
	background: #fff;
	& &__contentTop {
		padding: 0;
	}

	& &__contents {
		margin-right: auto;
		margin-left: auto;
		width: 100%;
		max-width: 1150px;
		display: flex;
		flex-wrap: nowrap;
	}
	& &__main {
		width: 100%;
		overflow: hidden;
	}
	& &__mainWithColumn {
		width: 100%;
		@include media_desktop() {
			width: 75%;
		}
	}
	& &__mainBetweenColumn {
		width: 100%;
		@include media_desktop() {
			width: 50%;
		}
	}
	& &__left,
	& &__right {
		display: none;
		@include media_desktop() {
			display: block;
			width: 25%;
		}
	}
}

.ec-headerRole {
	@include container;
	padding-top: 15px;
	position: relative;
	&:after {
		display: none;
	}
	@include media_desktop {
		@include clearfix;
	}
	&::before {
		display: none;
	}
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: auto;
	@include media_desktop {
		width: 100%;
		@include clearfix;
	}
	& &__title {
		width: 100%;
	}
	& &__navSP {
		display: block;
		position: absolute;
		top: 15px;
		width: 27%;
		right: 0;
		text-align: right;
		@include media_desktop {
			display: none;
		}
	}
}

.ec-headerNaviRole {
	@include container;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 15px;

	.fa-bars {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@include media_desktop {
		padding-bottom: 40px;
	}

	& &__left {
		width: calc(100% / 3);
	}

	& &__search {
		display: none;
		@include media_desktop() {
			display: inline-block;
			margin-top: 10px;
			@include reset_link;
		}
	}
	& &__navSP {
		display: block;
		@include media_desktop() {
			display: none;
			@include reset_link;
		}
	}

	& &__right {
		width: calc(100% * 2 / 3);
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	& &__nav {
		display: inline-block;
		@include reset_link;
	}
	& &__cart {
		display: inline-block;
		@include reset_link;
	}
}

.ec-headerNavSP {
	display: block;
	cursor: pointer;
	//display: inline-block;
	border-radius: 50%;
	box-sizing: border-box;
	padding: 10px;
	width: 40px;
	height: 40px;
	font-size: 18px;
	text-align: center;
	color: black;
	background: white;
	position: fixed;
	top: 10px;
	left: 10px;
	z-index: 1000;

	.fas {
		vertical-align: top;
	}

	@include media_desktop {
		display: none;
	}
}
.ec-headerNavSP.is-active {
	display: none;
}

/*
ヘッダー：タイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

Styleguide 11.2.1
*/
.ec-headerTitle {
	@include commonStyle();
	& &__title {
		text-align: center;
		h1 {
			margin: 0;
			padding: 0;
		}
		a {
			display: inline-block;
			margin-bottom: 30px;
			text-decoration: none;
			font-size: 20px;

			@include media_desktop() {
				font-size: 40px;
			}
			font-weight: bold;
			color: black;

			&:hover {
				opacity: 0.8;
			}
		}
	}
	& &__subtitle {
		font-size: 10px;
		text-align: center;
		@include media_desktop() {
			font-size: 16px;
			margin-bottom: 10px;
		}
		a {
			display: inline-block;
			color: #0092c4;
			text-decoration: none;
			cursor: pointer;
		}
	}
}

/*
ヘッダー：ユーザナビゲーション

ヘッダー内でユーザに関与するナビゲーションコンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__nav`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
.ec-headerNav {
	text-align: right;
	& &__item {
		margin-left: 0;
		display: inline-block;
		font-size: 28px;
	}
	& &__itemIcon {
		display: inline-block;
		margin-right: 10px;
		margin-left: 10px;
		font-size: 18px;
		color: black;
		@include media_desktop {
			margin-right: 0;
			font-size: 20px;
		}
	}
	& &__itemLink {
		display: none;
		margin-right: 5px;
		font-size: 14px;
		vertical-align: middle;
		color: black;
		@include media_desktop {
			display: inline-block;
		}
	}
}

/*
ヘッダー：検索ボックス

ヘッダー内で使用される商品検索コンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__search`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerSearch

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.4
*/
.ec-headerSearch {
	@include clearfix;
	& &__category {
		float: none;
		@include media_desktop {
			float: left;
			width: 43%;
		}
		.ec-select {
			overflow: hidden;
			width: 100%;
			margin: 0;
			text-align: center;

			select {
				width: 100%;
				cursor: pointer;
				padding: 8px 24px 8px 8px;
				text-indent: 0.01px;
				text-overflow: ellipsis;
				border: none;
				outline: none;
				background: transparent;
				background-image: none;
				box-shadow: none;
				appearance: none;
				color: #fff;

				@include media_desktop {
					max-width: 165px;
					height: 36px;
				}

				option {
					color: #000;
				}

				&::-ms-expand {
					display: none;
				}
			}

			&.ec-select_search {
				position: relative;
				border: 0;
				background: #000;
				color: #fff;
				border-top-right-radius: 10px;
				border-top-left-radius: 10px;

				@include media_desktop {
					border-top-right-radius: inherit;
					border-top-left-radius: 50px;
					border-bottom-left-radius: 50px;
				}

				&::before {
					position: absolute;
					top: 0.8em;
					right: 0.4em;
					width: 0;
					height: 0;
					padding: 0;
					content: "";
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					border-top: 6px solid #fff;
					pointer-events: none;
				}
			}
		}
	}
	& &__keyword {
		position: relative;
		color: $clrDarkGray;
		border: 1px solid #ccc;
		background-color: #f6f6f6;
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;

		@include media_desktop {
			float: right;
			width: 57%;
			border-bottom-left-radius: inherit;
			border-top-right-radius: 50px;
			border-bottom-right-radius: 50px;
		}
		input[type="search"] {
			width: 100%;
			height: 34px;
			font-size: 16px;
			border: 0 none;
			padding: 0.5em 50px 0.5em 1em;
			box-shadow: none;
			background: none;
			box-sizing: border-box;
			margin-bottom: 0;
			@include media_desktop {
				font-size: 12px;
			}
		}
		.ec-icon {
			width: 22px;
			height: 22px;
		}
	}
	& &__keywordBtn {
		border: 0;
		background: none;
		position: absolute;
		right: 5px;
		top: 50%;
		transform: translateY(-55%);
		display: block;
		white-space: nowrap;
		z-index: 1;
	}
}

/*
ヘッダー：カテゴリナビ

ヘッダー内で使用されている商品のカテゴリ一覧として使用します。
`li`の中に`ul > li`要素を入れることで、階層を深くする事ができます。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-categoryNaviRole" style="padding-bottom:150px;">
  <sg-wrapper-content/>
</div>

Styleguide 11.2.5
*/
.ec-categoryNaviRole {
	@include container;
	display: none;
	@include media_desktop() {
		display: block;
		width: 100%;
		@include reset_link;
	}
}

.ec-itemNav {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	text-align: center;
}

.ec-itemNav__nav {
	display: block;
	margin: 0 auto;
	padding: 0;
	width: auto;
	height: auto;
	list-style-type: none;
	text-align: center;
	vertical-align: bottom;
	@include media_desktop {
		display: inline-block;
	}
}

.ec-itemNav__nav li {
	float: none;
	margin: 0;
	padding: 0;
	width: 100%;
	text-align: center;
	position: relative;
	@include media_desktop {
		float: left;
		width: auto;
	}
}

.ec-itemNav__nav li a {
	display: block;
	border-bottom: 1px solid #e8e8e8;
	margin: 0;
	padding: 16px;
	height: auto;
	color: #2e3233;
	font-size: 16px;
	font-weight: bold;
	line-height: 20px;
	text-decoration: none;
	text-align: left;
	background: #fff;
	border-bottom: 1px solid #e8e8e8;
	@include media_desktop {
		text-align: center;
		border-bottom: none;
	}
}

.ec-itemNav__nav li ul {
	display: none;
	z-index: 0;
	margin: 0;
	padding: 0;
	min-width: 200px;
	list-style: none;
	position: static;
	top: 100%;
	left: 0;
	@include media_desktop {
		display: block;
		z-index: 100;
		position: absolute;
	}
}

.ec-itemNav__nav li ul li {
	overflow: hidden;
	width: 100%;
	height: auto;
	transition: 0.3s;
	@include media_desktop {
		overflow: hidden;
		height: 0;
	}
}

.ec-itemNav__nav li ul li a {
	border-bottom: 1px solid #e8e8e8;
	padding: 16px 22px 16px 16px;
	font-size: 16px;
	font-weight: bold;
	color: white;
	text-align: left;
	background: black;
}

.ec-itemNav__nav > li:hover > a {
	background: #fafafa;
}

.ec-itemNav__nav > li:hover li:hover > a {
	background: #333;
}

.ec-itemNav__nav > li:hover > ul > li {
	@include media_desktop {
		overflow: visible;
		height: auto;
	}
}

.ec-itemNav__nav li ul li ul {
	top: 0;
	left: 100%;
	width: auto;
}

.ec-itemNav__nav li ul li ul:before {
	@include media_desktop {
		content: "\f054";
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		font-size: 12px;
		color: white;
		position: absolute;
		top: 19px;
		right: auto;
		left: -20px;
	}
}

.ec-itemNav__nav li ul li:hover > ul > li {
	@include media_desktop {
		overflow: visible;
		height: auto;
		width: auto;
	}
}

.ec-itemNav__nav li ul li ul li a {
	background: #7d7d7d;
}

.ec-itemNav__nav li:hover ul li ul li a:hover {
	background: #333;
}

/*
ヘッダー：SPヘッダー

SP時のみ出現するヘッダーに関係するコンポーネントです。<br>
ex [トップページ](http://demo3.ec-cube.net/)画面サイズが768px以下に該当。<br>
<br>
`.ec-drawerRole`：SPのドロワー内の要素をwrapするコンポーネントです。<br>
`.ec-headerSearch`、`.ec-headerNav`、`.ec-itemNav`は`.ec-drawerRole`の子要素にある場合、ドロワーに適したスタイルに変化します。<br><br>
`.ec-overlayRole`：SPのドロワー出現時にz-indexがドロワー以下の要素に半透明の黒背景をかぶせるコンポーネントです。<br>

Styleguide 11.2.6
*/

.ec-drawerRole {
	overflow-y: scroll;
	background: black;
	width: 260px;
	height: 100vh;
	transform: translateX(-300px);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	transition: z-index 0ms 1ms;
	@include media_desktop() {
		display: none;
	}

	.ec-headerSearchArea {
		padding: 20px 10px;
		width: 100%;
		background: #f8f8f8;
	}

	.ec-headerSearch {
		padding: 16px 8px 26px;
		background: #ebebeb;
		color: #636378;
		select {
			width: 100% !important;
		}
	}

	.ec-headerCategoryArea {
		.ec-headerCategoryArea__heading {
			border-top: 1px solid #cccccc;
			border-bottom: 1px solid #cccccc;
			padding: 1em 10px;
			font-size: 16px;
			font-weight: bold;
			color: black;
			background: #f8f8f8;
		}

		p {
			margin-top: 0;
			margin-bottom: 0;
		}

		.ec-itemNav__nav li a {
			border-bottom: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
			color: black;
			font-weight: normal;
			background: #f8f8f8;
		}

		.ec-itemNav__nav li ul li a {
			border-bottom: 1px solid #ccc;
			padding-left: 20px;
			font-weight: normal;
			background: white;
		}

		.ec-itemNav__nav > li:hover > a {
			background: #f8f8f8;
		}

		.ec-itemNav__nav > li:hover li:hover > a {
			background: white;
		}

		.ec-itemNav__nav li ul li ul li a {
			padding-left: 40px;
			color: black;
			background: white;
		}

		.ec-itemNav__nav li:hover ul li ul li a:hover {
			background: white;
		}

		.ec-itemNav__nav li ul li ul li ul li a {
			padding-left: 60px;
			font-weight: normal;
		}
	}
	.ec-headerLinkArea {
		background: black;

		.ec-headerLink__list {
			border-top: 1px solid #ccc;
		}

		.ec-headerLink__item {
			display: block;
			border-bottom: 1px solid #ccc;
			padding: 15px 20px;
			font-size: 16px;
			font-weight: bold;
			color: white;
		}
		.ec-headerLink__icon {
			display: inline-block;
			width: 28px;
			font-size: 17px;
		}
	}
}

.ec-drawerRoleClose {
	display: none;
	cursor: pointer;
	border-radius: 50%;
	box-sizing: border-box;
	padding: 10px;
	width: 40px;
	height: 40px;
	font-size: 18px;
	text-align: center;
	color: black;
	background: white;
	position: fixed;
	top: 10px;
	left: 270px;
	z-index: 1000;

	.fas {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	@include media_desktop {
		display: none;
	}
}

.ec-drawerRole.is_active {
	display: block;
	transform: translateX(0);
	transition: all 0.3s;
	z-index: 100000;

	@include media_desktop() {
		display: none;
	}
}
.ec-drawerRoleClose.is_active {
	display: inline-block;
	transition: all 0.3s;

	@include media_desktop {
		display: none;
	}
}

.ec-overlayRole {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	opacity: 0;
	background: transparent;
	transform: translateX(0);
	transition: all 0.3s;
	visibility: hidden;

	@include media_desktop {
		display: none;
	}
}

.have_curtain .ec-overlayRole {
	display: block;
	opacity: 1;
	background: rgba(0, 0, 0, 0.5);
	visibility: visible;

	@include media_desktop {
		display: none;
	}
}

/*
ヘッダー：test

test

Markup:
span.ec-itemAccordionParent test1
ul.ec-itemNavAccordion
  li.ec-itemNavAccordion__item
    a(href='') test2
    ul.ec-itemNavAccordion
      li.ec-itemNavAccordion__item
        a(href='') test3
        ul.ec-itemNavAccordion
          li.ec-itemNavAccordion__item
            a(href='') test4

Styleguide 11.2.7
*/

.ec-itemNavAccordion {
	display: none;
}
