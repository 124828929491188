/*
住所一覧

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [マイページ内 お届け先編集](http://demo3.ec-cube.net/mypage/delivery)

Markup:
include /assets/tmpl/elements/17.1.address.pug
+ec-addressList
+ec-addressRole

sg-wrapper:
<div class="ec-addressRole">
  <sg-wrapper-content/>
</div>

Styleguide 17.1

*/
.ec-addressRole {
	& &__item {
		border-top: 1px dotted #ccc;
	}
	& &__actions {
		margin-top: 32px;
		padding-bottom: 20px;
		border-bottom: 1px dotted #ccc;
	}
}
.ec-addressList {
	& &__item {
		display: table;
		width: 100%;
		position: relative;
		border-bottom: 1px dotted #ccc;
		font-size: 0.8125rem;
		@include media_desktop {
			font-size: 15px;
			font-size: 0.9375rem;
		}
	}
	& &__remove {
		display: table-cell;
		vertical-align: middle;
		padding: 15px 10px;
		text-align: center;
		@include media_desktop {
			padding: 15px;
		}
		&:nth-of-type(1) {
			padding: 15px 10px 0;
			text-align: left;
			display: table-caption;
			@include media_desktop {
				min-width: 150px;
				display: table-cell;
				padding: 15px;
			}
		}
		.ec-icon img {
			width: 1em;
			height: 1em;
		}
		input {
			margin: -2px 0 0;
			appearance: revert !important;
		}
	}
	& &__address {
		display: table-cell;
		vertical-align: middle;
		padding: 10px 10px 15px;
		margin-right: 4em;
		width: 80%;
		@include media_desktop {
			padding: 15px;
		}
	}
	& &__action {
		position: relative;
		vertical-align: middle;
		text-align: right;
		top: 27px;
		padding-right: 10px;
	}
}
